import React from 'react'
import Helmet from 'react-helmet'
import {graphql} from 'gatsby'
import Layout from '../layouts'
import Nav from '../components/nav'
import Article from '../components/contentful/article'
import Metadata from '../layouts/metadata'

const IndexPage = ({data, location}) => {
	const latestArticle = data.articles.edges[0].node

	return (
		<Layout location={location}>
			<Metadata image={latestArticle.illustration.file.url} />
			<Helmet>
				<body className="home" />
			</Helmet>
			<Nav links={data.links} pathname={location.pathname} />
			<Article {...latestArticle} />
		</Layout>
	)
}

export default IndexPage

export const query = graphql`
	query {
		links: boobLink(next: {eq: ""}) {
			previous
		}
		articles: allContentfulArticle(
			limit: 1
			sort: {fields: [createdAt], order: DESC}
		) {
			edges {
				node {
					title
					illustration {
						...ContentfulImageFragment
					}
					caption
					body {
						text: childMarkdownRemark {
							html
						}
					}
				}
			}
		}
	}
`
