import React from 'react'
import PropTypes from 'prop-types'
import {DangerousHTML} from './html'
import Image from './image'

const Article = ({title, illustration, caption, body}) => (
	<article className="content-block">
		<h2 className="content-block-title">{title}</h2>
		<figure className="full-bleed-image">
			<Image {...illustration} />
			{caption && <figcaption>{caption}</figcaption>}
		</figure>
		<div className="ribbon" />
		<section className="content-block-article">
			<DangerousHTML>{body.text.html}</DangerousHTML>
		</section>
	</article>
)

export default Article

Article.propTypes = {
	title: PropTypes.string.isRequired,
	illustration: PropTypes.shape(Image.propTypes).isRequired,
	caption: PropTypes.string,
	body: PropTypes.shape({
		text: PropTypes.shape({
			html: PropTypes.string.isRequired,
		}).isRequired,
	}).isRequired,
}
