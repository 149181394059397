import React from 'react'
import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import Img from 'gatsby-image'

const Image = ({small, title, description, full, thumb}) => (
	<Img title={title} alt={description} fluid={small ? thumb : full} />
)

Image.propTypes = {
	small: PropTypes.boolean,
	title: PropTypes.string,
	description: PropTypes.string,
	full: PropTypes.object.isRequired,
	thumb: PropTypes.object.isRequired,
}

export default Image

export const contentfulImageFragment = graphql`
	fragment ContentfulImageFragment on ContentfulAsset {
		title
		description
		file {
			url
		}
		full: fluid(maxWidth: 1200) {
			...GatsbyContentfulFluid
		}
		thumb: fluid(maxWidth: 700) {
			...GatsbyContentfulFluid
		}
	}
`
